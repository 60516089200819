import { apiSlice } from './apiSlice';

export const traineeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTrainees: builder.query({
      query: ({page, limit,traineeName} = {}) => {
        let url = `https://control.clickcoach.app/index.php?route=rest/coach/trainee&filter_name=${traineeName}`
        if (page && limit) {
          url += `&page=${page}&limit=${limit}`;
        }
        return url;
      },
      transformResponse: (response) => response.data,
      providesTags: (result) => 
        result
          ? [...result?.trainees?.map(({ id }) => ({ type: 'Trainee', id })), { type: 'Trainee', id: 'LIST' }]
          : [{ type: 'Trainee', id: 'LIST' },],
          invalidatesTags: [ { type: 'Payment' },
            { type: 'Incomes' },
            { type: 'Trainee', id: 'LIST' },
            { type: 'Sessions' }, { type: 'Dashboard' },
            ],
    }),
    getTraineeById: builder.query({
      query: (traineeId) => ({
        url: `coach/trainee/${traineeId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { traineeId }) => [{ type: 'trainee_by_id', traineeId }, { type: 'Dashboard' },],
    }),
    addTrainee: builder.mutation({
      query: (newTrainee) => ({
        url: `coach/trainee`,
        method: 'POST',
        body: newTrainee,
      }),
      invalidatesTags: [ { type: 'Payment' },
        { type: 'Incomes' },
        { type: 'Trainee', id: 'LIST' },
        { type: 'Sessions' }, { type: 'Dashboard' },
        ],
    }),
    editTrainee: builder.mutation({
      query: (newTrainee) => ({
        url: `coach/trainee/${newTrainee.id}`,
        method: 'PUT',
        body: newTrainee,
      }),
      invalidatesTags: (result, error, { traineeId })=>[ { type: 'Payment' },
        { type: 'Incomes' },
        { type: 'Trainee', id: 'LIST' },
        { type: 'Sessions' },{ type: 'trainee_by_id', traineeId }, { type: 'Dashboard' },],
    }),
    deleteTrainee: builder.mutation({
      query: (id) => ({
        url: `coach/trainee/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) =>  [ { type: 'Payment' },
        { type: 'Incomes' },
        { type: 'Trainee', id },
        { type: 'Sessions' }, { type: 'Dashboard' },],
    }),
  }),
});

// Export hooks for the injected endpoints
export const { useGetTraineesQuery, useAddTraineeMutation, useEditTraineeMutation, useDeleteTraineeMutation ,useGetTraineeByIdQuery} = traineeApi;
